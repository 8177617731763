<template>
    <div class="work-main">
        <div class="important-msgs">
            <div class="time-now">
                {{time_now}}
            </div>
            <div class="add-new-msg" @click="update_msg">
                <i class="material-icons">mode_edit</i>
            </div>
            <div class="msg-box" v-if="work_msg">
                <div class="msg-title">
                    <p>{{work_msg.title}}</p>
                </div>
                <div class="msg-text">
                    <p>{{work_msg.msg}}</p>
                </div>
            </div>
            <div class="loading-msg" v-if="!work_msg">
                <i class="el-icon-loading"/>
            </div>
        </div>
        <div class="menu-bar">
            <div class="menu-btn" @click="menu_state = 'משמרת'">
                משמרת
            </div>
            <div class="menu-btn" @click="menu_state = 'ניהול'">
                ניהול
            </div>
            <div class="menu-btn" @click="menu_state = 'תפריט'">
                תפריט
            </div>
            <div class="menu-btn" @click="menu_state = 'הזמנות'">
                הזמנות
            </div>
        </div>

        <div class="btns-grid" v-if="menu_state == 'משמרת' && is_shift">
            <div class="shift-quick-info">
                <p style="width:100%; text-align:center; color: var(--success); font-size: 18px; margin-bottom:5px;">משמרת פעילה</p>
                <p>תאריך התחלה: {{store.state.shift.start_date.toLocaleString('he')}}</p>
                <p>עובדים במשמרת: {{workers_in.shift_counter}}</p>
                <p>עובדים בשירות: {{workers_in.service_counter}}</p>
            </div>

            <div class="work-btn" @click="go_to('clockin')">
                <i class="material-icons">perm_identity</i>
                <p>כניסה למשמרת</p>
            </div>
            <div class="work-btn" @click="go_to('clockout')">
                <i class="material-icons">person_off</i>
                <p>יציאה ממשמרת</p>
            </div>
            <div class="work-btn" @click="go_to('servicein')">
                <i class="material-icons">person_add</i>
                <p>כניסה לשירות</p>
            </div>
            <div class="work-btn" @click="go_to('serviceout')">
                <i class="material-icons">person_remove_alt_1</i>
                <p>יציאה משירות</p>
            </div>
        </div>
        <div class="no-shift" v-if="menu_state == 'משמרת' && !is_shift">
            <img src="https://cdn-icons-png.flaticon.com/512/2913/2913133.png" alt="">
            <p style="font-size:20px; color:var(--yellow);">אין משמרת פעילה כרגע</p>
            <el-button type="success" style="margin-top:25px; width:280px;font-size:20px; text-shadow: 0 0 5px black; height:50px;" @click="new_shift">פתח משמרת</el-button>
            <el-button type="primary" style="margin:0; margin-top:25px; width:280px;font-size:20px; text-shadow: 0 0 5px black; height:50px;" @click="go_to('lastshift')">משמרת אחרונה</el-button>
        </div>

        <div class="btns-grid" v-if="menu_state == 'ניהול'">
            <div class="work-btn" @click="go_to('shift')">
                <i class="material-icons">point_of_sale</i>
                <p>ניהול משמרת</p>
            </div>
            <div class="work-btn">
                <i class="material-icons">paid</i>
                <p>טיפים</p>
            </div>
            <div class="work-btn">
                <i class="material-icons">insert_invitation</i>
                <p>לוז משמרות</p>
            </div>
            <div class="work-btn" @click="go_to('employees')">
                <i class="material-icons">people_alt</i>
                <p>ניהול עובדים</p>
            </div>
            <div class="work-btn">
                <i class="material-icons">summarize</i>
                <p>דוחות</p>
            </div>
            <div class="work-btn">
                <i class="material-icons">construction</i>
                <p>קריאות שירות</p>
            </div>
        </div>

        <div class="btns-grid" v-if="menu_state == 'תפריט'">

        </div>

        <div class="btns-grid" v-if="menu_state == 'הזמנות'">

        </div>

        <div class="btns-grid" v-if="menu_state == 'יחסי ציבור'">
            <div class="work-btn">
                <i class="material-icons">celebration</i>
                <p>יחסי ציבור</p>
            </div>
        </div>
    </div>  
</template>

<script>
import { computed, ref } from '@vue/reactivity'
import router from '../router'
import { onMounted, onUnmounted } from '@vue/runtime-core'
import { projectAuth, projectFirestore } from '../firebase/config'
import Swal from 'sweetalert2'
import { slide_pop_successs } from '../Methods/Msgs'
import { is_active_shift, new_shift } from '../Methods/Shift'
import store from '../store'

export default {
components:{},
setup(){
    const menu_state = ref('משמרת');
    
    const is_shift = ref(computed(() => {
        let is = is_active_shift()
        return is
    }))

    const go_to = (path) => {
        router.push(path)
    }
    
    const work_msg = ref(null);
    const get_work_msg = async () => {
        const doc = await projectFirestore.collection('Utils').doc('work_msg').get()
        if(doc.exists){
            work_msg.value = doc.data()
        }
    }

    const update_msg = async () => {
        let title = '';
        let msg = '';
        Swal.fire({
            icon: 'question',
            title: 'כותרת ההודעה',
            input: 'text',
            confirmButtonText: 'המשך',
            cancelButtonText: 'בטל',
            showCancelButton: true
        }).then(res => {
            if(res.isConfirmed){
                if(res.value.length > 5){
                    title = res.value
                    Swal.fire({
                        icon: 'question',
                        title: title,
                        text: 'הכנס את מלל ההודעה',
                        input: 'textarea',
                        confirmButtonText: 'סיים',
                        cancelButtonText: 'בטל',
                        showCancelButton: true
                    }).then(async rez => {
                        if(rez.isConfirmed){
                            if(rez.value.length > 5){
                                msg = rez.value
                                await projectFirestore.collection('Utils').doc('work_msg').update({
                                    title: title,
                                    msg: msg,
                                    user: projectAuth.currentUser.uid,
                                    date: new Date()
                                })
                                work_msg.value = await (await projectFirestore.collection('Utils').doc('work_msg').get()).data()
                                slide_pop_successs("ההודעה עודכנה בהצלחה!")
                            }
                        }
                    })
                }
            }
        })
    }

    const time_now = ref(null)

    let timer = setInterval(() => {
        time_now.value = new Date().toLocaleString('he')
    }, 1000);

    const workers_in = ref(computed(() => {
        let  service_counter = 0;
        let shift_counter = 0;
        store.state.shift.workers.forEach(worker => {
            if(worker.service.active){
                service_counter +=1
            }
            if(worker.shift.active){
                shift_counter +=1
            }
        })
        return {service_counter:service_counter, shift_counter:shift_counter}
    }))

    onMounted(() => {
        get_work_msg()
    })

    onUnmounted(() => {
        clearInterval(timer)
    })

    return{
        go_to, work_msg, time_now, update_msg, menu_state,is_shift, new_shift,store,
        workers_in,
    }
}
}
</script>

<style scoped>
.work-main{
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}
.important-msgs{
    width: 100%;
    height: 200px;
    padding: 5px;
    flex-shrink: 0;
    position: relative;
}
.msg-box{
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--alert-purple);
    border-radius: 10px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px;
    overflow-y: auto;
    justify-content: center;
}
.loading-msg{
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--alert-purple);
    border-radius: 10px;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    overflow-y: auto;
    font-size: 80px;
    color: white;
}
.msg-title{
    width: fit-content;
    height: 40px;
    /* background: var(--success); */
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    color: white;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.712);
    padding: 0 10px 0 10px;
}
.msg-text{
    width: 100%;
    max-width: 600px;
    font-size: 23px;
    color: var(--yellow);
    height: fit-content;
    white-space: pre-wrap;
}
.btns-grid{
    position: relative;
    width: 100%;
    height: calc(100% - 270px);
    padding: 0 5px 5px 5px;
    display: grid;
    grid-template-columns:repeat(auto-fill,minmax(250px, 1fr));
    grid-auto-rows: 200px;
    grid-gap: 5px;
    overflow-y: auto;
}
.work-btn{
    background: var(--secondary);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    cursor: pointer;
    transition: 0.25s;
    border-radius: 10px;
}
.work-btn i {
    font-size: 100px;
}
.work-btn p{
    font-size: 30px;
}
.work-btn:hover{
    transform:scale(1.01);
    color: rgb(75, 75, 199);
}
.add-new-msg{
    position: absolute;
    top: 15px;
    left: 15px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: var(--purple);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 2;
    color: white;
    text-shadow: 0 0 3px grey;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.286);
}
.time-now{
    position: absolute;
    top: 15px;
    right: 15px;
    width: fit-content;
    color: var(--success);
}
.menu-bar{
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow-x: auto;
    margin-bottom: 5px;
    padding-right: 5px;
}
.menu-btn{
    width: fit-content;
    height: 100%;
    background: var(--purple);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-size: 18px;
    color: white;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.438);
    margin-left: 5px;
    padding: 5px;
    min-width: 100px;
    cursor: pointer;
}
.no-shift{
    width: 100%;
    height: calc(100% - 280px);
    display: flex;
    flex-direction: column;
    align-items: center;
}
.no-shift img{
    margin-top: 25px;
    max-width: 180px;
    height: auto;
    margin-bottom: 15px;
}
.shift-quick-info{
    position: absolute;
    bottom: 5px;
    width: 100%;
    padding: 5px;
    height: 100px;
    background: var(--alert-purple);
    color: white;
    font-size: 16px;
}
@media only screen and (max-width: 600px) {
    .msg-text{
        font-size: 17px;
    }
    .btns-grid{
        grid-template-columns:repeat(auto-fill,minmax(100px, 1fr));
        grid-auto-rows: 150px;
    }
    .work-btn i{
        font-size: 50px;
    }
    .work-btn p{
        text-align: center;
        font-size: 18px;
    }
}
</style>